import styled from "styled-components";

type NavbarStyleProps = {
  displayEnvIndicator: boolean;
};

export const NavbarContainer = styled.div<NavbarStyleProps>`
  background: ${(props) => props.theme.nav.mobile.nav.background};
  position: fixed;
  top: ${(props) => (props.displayEnvIndicator ? "18px" : 0)};
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
`;
