import styled from "styled-components";

export const NavMenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
`;

export const NavMenuDropdown = styled.div`
  top: 45px;
  position: absolute;
  width: auto;
  color: ${(props) => props.theme.nav.horizontal.nav.color};
  background: ${(props) => props.theme.nav.horizontal.nav.background};
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  z-index: 1055;
`;
