import styled from "styled-components";

export const Hamburger = styled.button.attrs(() => ({
  id: "navButton best-nav-hamburger",
  type: "button",
  "data-toggle": "collapse",
  "data-target": "#navbarSupportedContent",
  "aria-controls": "navbarSupportedContent",
  "aria-expanded": "false",
  "aria-label": "Toggle navigation",
}))`
  &&& {
    border: none;
    background: transparent;
    outline: none !important;
    z-index: 1055;
    height: 35px;
    &:focus {
      &&& {
        outline: none !important;
        background: transparent;
      }
    }
    &:hover > .icon-bar:nth-child(2) {
      width: 30px;
      transition: ease all 0.2s;
    }
    &:active > .icon-bar:nth-child(2) {
      width: 30px;
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(2) {
      opacity: 0;
      transition: ease all 0.2s;
    }
    &:not(.mobile-nav-closed) .icon-bar:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
      transition: ease all 0.2s;
    }
    .icon-bar {
      background-color: ${(props) => props.theme.nav.mobile.nav.color};
      transform: rotate(0deg) translate(0px, 0px);
      transition: ease all 0.2s;
      display: block;
      width: 30px;
      height: 2px;
      border-radius: 1px;
      outline: none !important;
    }
    .icon-bar + .icon-bar {
      margin-top: 4px;
      outline: none !important;
    }
  }
`;
