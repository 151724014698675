import React, { AnchorHTMLAttributes } from "react";
import { NavigationLinkType } from "../../../../../types";
import QuickLink from "./QuickLink";
import { QuickLinksContainer } from "./QuickLinkStyles";

interface QuickLinksProps {
  links?: NavigationLinkType[];
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const QuickLinks = ({ links = [], customLinkComponent }: QuickLinksProps) => {
  return (
    <QuickLinksContainer>
      {links.map((ql) => (
        <QuickLink {...ql} key={ql.name} customLinkComponent={customLinkComponent} />
      ))}
    </QuickLinksContainer>
  );
};

export default QuickLinks;
