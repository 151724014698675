import React from "react";
import { useTheme } from "../../hooks";
import { LoadingThemeType } from "../../types";
import { LoadingType } from "../../types/LoadingTypes";
import Loader from "./Loader";
import { LoadingAnimation, LoadingLabel, Overlay, Parent } from "./LoadingStyles";

type LoadingBlockerProps = {
  /** Type of loader to use */
  type: LoadingType;
  /** Label under the loader */
  label?: string;
  /** if the loader is animated */
  animated?: boolean;
  /** overrideable styles */
  styles?: LoadingThemeType;
};

const Loading: React.FC<React.PropsWithChildren<LoadingBlockerProps>> = ({
  type,
  animated = true,
  label,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: LoadingThemeType = { ...Theme.loading, ...styles };

  return (
    <Parent id="loading-blocker" styles={StylesOverride}>
      <Overlay styles={StylesOverride}>
        <LoadingAnimation animated={animated}>
          <Loader type={type} />
          <LoadingLabel styles={StylesOverride}>{label}</LoadingLabel>
        </LoadingAnimation>
      </Overlay>
    </Parent>
  );
};

export default Loading;
