import React, { AnchorHTMLAttributes } from "react";
import { EnvironmentType, NavigationConfigType, NavigationLinkWithSubLinks } from "../../../../types";
import { useActivePath } from "../shared/ActivePathContext";
import Brand from "../shared/brand/Brand";
import Nav from "../shared/nav/Nav";
import NavItem from "../shared/navItem/NavItem";
import NavSignoutBar from "../shared/navSignoutBar/NavSignoutBar";
import { HorizontalNavigationSpacer } from "./HorizontalNavigationStyles";
import NavMenu from "./navMenu/NavMenu";
import NavStatus from "./navStatus/NavStatus";
import Navbar from "./navbar/Navbar";

interface HorizontalNavigationProps {
  config: NavigationConfigType;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const HorizontalNavigation = ({ config, customLinkComponent }: HorizontalNavigationProps) => {
  const {
    loggedIn,
    username,
    startLogo,
    centerLogo,
    endLogo,
    navigationLinks = [],
    usernameDropdownLinks = [],
    builds = [],
    env = "prod" as EnvironmentType,
    displayEnvIndicator = false,
    displayPermissionsIndicator = false,
    permissions = undefined,
    status,
  } = config;
  const { isActivePath } = useActivePath();

  return (
    <>
      {loggedIn && (
        <NavSignoutBar
          username={username}
          links={usernameDropdownLinks}
          displayEnvIndicator={displayEnvIndicator}
          displayPermissionsIndicator={displayPermissionsIndicator}
          permissions={permissions}
          env={env}
        />
      )}
      <Navbar loggedIn={loggedIn}>
        {!!startLogo?.visible && (
          <Nav align="start">
            <Brand
              logo={startLogo!.logo}
              link={startLogo!.link}
              text={startLogo!.text}
              contentHeight={startLogo!.logoHeight}
              builds={builds}
              customLinkComponent={customLinkComponent}
            />
          </Nav>
        )}

        {loggedIn && !!navigationLinks?.length && (
          <Nav>
            {navigationLinks.map((link: NavigationLinkWithSubLinks) => {
              if (link.visible !== false) {
                if (!!link.subLinks?.length) {
                  return <NavMenu {...link} customLinkComponent={customLinkComponent} key={link.name} />;
                } else {
                  return (
                    <NavItem
                      {...link}
                      active={isActivePath(link)}
                      customLinkComponent={customLinkComponent}
                      key={link.name}
                    />
                  );
                }
              } else {
                return null;
              }
            })}
          </Nav>
        )}

        {!!centerLogo?.visible && (
          <Nav align="center">
            <Brand
              logo={centerLogo.logo}
              link={centerLogo.link}
              text={centerLogo.text}
              contentHeight={centerLogo!.logoHeight}
              customLinkComponent={customLinkComponent}
            />
          </Nav>
        )}

        {!!endLogo?.visible && (
          <Nav align="end">
            <Brand
              logo={endLogo.logo}
              link={endLogo.link}
              text={endLogo.text}
              contentHeight={endLogo!.logoHeight}
              customLinkComponent={customLinkComponent}
            />
          </Nav>
        )}
      </Navbar>
      {loggedIn && <NavStatus status={status} />}
      <HorizontalNavigationSpacer $loggedIn={loggedIn} $hasStatus={!!status?.text?.length} />
    </>
  );
};

export default HorizontalNavigation;
