import clsx from "clsx";
import React from "react";
import { useTheme } from "../../../../../hooks";
import { EnvironmentType, IndicatorThemeType } from "../../../../../types";
import { Indicator } from "./EnvIndicatorStyles";

type EnvIndicatorProps = {
  className?: string;
  env?: EnvironmentType;
  styles?: IndicatorThemeType;
};

const EnvIndicator: React.FC<React.PropsWithChildren<EnvIndicatorProps>> = ({
  env = "",
  className = "",
  styles = {},
}) => {
  const { Theme } = useTheme();
  const DefaultStyle: IndicatorThemeType = { ...Theme.environment };
  const classes: string = clsx("best-env-indicator d-flex justify-content-center align-items-center", className);
  const CompStyles: IndicatorThemeType = { ...DefaultStyle, ...styles } as IndicatorThemeType;

  switch (env) {
    case "local":
    case "dev":
    case "qa":
    case "stg":
      return (
        <Indicator className={classes} styles={CompStyles} env={env}>
          {env.toUpperCase()}
        </Indicator>
      );
    case "prod":
    case "production":
    default:
      return <></>;
  }
};

export default EnvIndicator;
