import styled from "styled-components";
import { Align, Direction } from "../../../../../types";

type NavContainerStyleProps = {
  align?: Align;
  direction?: Direction;
  navWidth?: string;
};

export const NavContainer = styled.ul<NavContainerStyleProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ align }) =>
    align === "end" ? "margin-left: auto;" : align === "center" ? "margin-left: auto; margin-right: auto;" : ""};
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  width: ${(props) => props.navWidth ?? undefined};
`;
