import styled from "styled-components";

const calculateHeight = (loggedIn: boolean, hasStatus: boolean): number => {
  //50 plus spacer
  let height = 50;
  if (loggedIn) {
    height += 20;
  }
  if (loggedIn && hasStatus) {
    height += 18;
  }
  return height;
};

interface HorizontalStylesProps {
  $loggedIn: boolean;
  $hasStatus: boolean;
}

export const HorizontalNavigationSpacer = styled.div<HorizontalStylesProps>`
  width: 100%;
  min-height: ${(props) => calculateHeight(props.$loggedIn, props.$hasStatus)}px;
`;
