import styled from "styled-components";

type NavSignoutBarStyleProps = {
  isVertical?: boolean;
};

export const NavSignoutBarContainer = styled.div<NavSignoutBarStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.background};
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.color};
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  z-index: 1055;
  font-family: ${(props) => props.theme.fontFamily};
`;

export const UsernameMenuDropdown = styled.div<NavSignoutBarStyleProps>`
  top: 16px;
  position: absolute;
  width: auto;
  min-width: 150px;
  border-radius: 6px;
  background: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.background};
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.color};
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  z-index: 1055;
`;

export const Username = styled.div<NavSignoutBarStyleProps>`
  margin-right: 0.5rem;
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.color};
  font-family: ${(props) => props.theme.fontFamily};
`;

export const Permission = styled.div<NavSignoutBarStyleProps>`
  margin-left: 1.5rem;
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.color};
  font-family: ${(props) => props.theme.fontFamily};
`;

export const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

export const UsernameDropdownItem = styled.div<NavSignoutBarStyleProps>`
  z-index: 1051;
  background: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.background};
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.color};
  font-family: ${(props) => props.theme.fontFamily};

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.hover.background};
    color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].username.links.hover.color};
  }
`;
