import styled from "styled-components";
import { EnvironmentType, IndicatorThemeType } from "../../../../../types";

export type IndicatorStylesProps = {
  env: EnvironmentType;
  styles: IndicatorThemeType;
};

export const Indicator = styled.div.attrs(() => ({
  id: "best-env-indicator",
}))<IndicatorStylesProps>`
  height: 100%;
  width: 50px;
  color: #ffffff;
  background: ${(props) =>
    props.styles && props.styles[props.env] && props.styles[props.env].background
      ? props.styles[props.env].background
      : "#e50021"};
  text-align: center;
  font-weight: bold;
  font-size: 0.75rem;
  z-index: 1050;
`;

Indicator.defaultProps = {
  env: "dev",
};
