import styled from "styled-components";
import { ThemeType } from "../../../../../types";

type NavItemStyleProps = {
  theme: ThemeType;
  active: boolean;
  isVertical?: boolean;
};

export const NavItemContainer = styled.li<NavItemStyleProps>`
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.color};
  list-style: none;
  float: left;
  margin-right: 0.125rem;
  max-height: 40px;
  border-radius: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.borderRadius ?? "6px"};
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;

  ${(props) =>
    props.active
      ? `background: ${props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.active.background}; color: ${
          props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.active.color
        };`
      : ""};

  &:hover {
    color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.hover.color};
    background: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.hover.background};
    cursor: pointer;
  }
`;

export const NavItemContentContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const NavItemName = styled.div`
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-family: ${(props) => props.theme.nav.fontFamily};
`;
