import styled from "styled-components";

type ComponentBarStyles = {
  top: number;
};

export const ComponentBarContainer = styled.div<ComponentBarStyles>`
  background: ${(props) => props.theme.nav.vertical.nav.background};
  height: auto;
  width: 100%;
  position: fixed;
  top: ${({ top }) => top}px;
  left: 0;
`;
