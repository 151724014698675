import React from "react";
import { NavImageStyled } from "./NavImageStyles";

type NavImageProps = {
  src: string;
  alt?: string;
  height?: string | number;
};

const NavImage: React.FC<NavImageProps> = ({ src, height, alt = "nav-logo" }) => {
  return <NavImageStyled src={src} height={height ?? "40px"} alt={alt} />;
};

export default NavImage;
