import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { NavigationLinkWithSubLinks } from "../../../../types/NavigationTypes";
import { BooleanMap } from "../../../../types/Objects";
import { matchPath } from "../../../../utils/navigation/NavigationUtil";
import { checkAllFalseAndSetFirstTrue } from "../../../../utils/ObjectUtil";
import { getWindow } from "../../../../utils/WindowUtil";

export type ActivePathState = {
  isActivePath: (link: NavigationLinkWithSubLinks) => boolean;
  makePathActive: (path: string) => void;
};

const ActivePathContext = createContext<ActivePathState | undefined>(undefined);
interface Props {
  navLinks: NavigationLinkWithSubLinks[] | undefined;
}

export const ActivePathContextProvider: React.FC<PropsWithChildren<Props>> = ({ children, navLinks }) => {
  const location = getWindow()?.location;
  const [isActiveMap, setIsActiveMap] = useState<BooleanMap>({});

  useEffect(() => {
    // After navlinks are set, make the path active
    if (!!navLinks?.length) {
      makePathActive(location?.pathname?.toLowerCase() || "/");
    }
  }, [navLinks, location]);

  const makePathActive = (path: string) => {
    const newIsActiveMap: BooleanMap = {};
    // when there is no link.to it is a parent of sublinks so use thename in the mao
    if (!!navLinks) {
      navLinks.forEach((link) => {
        const isLinkActive = !!link.to ? matchPath(link.to, path, navLinks) : link.name === path;
        newIsActiveMap[link.to || link.name] = isLinkActive;

        link.subLinks?.forEach((subLink) => {
          const isSubLinkActive = !!subLink.to ? matchPath(subLink.to, path) : subLink.name === path;
          newIsActiveMap[subLink.to || subLink.name] = isSubLinkActive;

          // If any subLink is active, set the parent link as active
          if (isSubLinkActive) newIsActiveMap[link.name] = true;
        });
      });
      //set the new map, if for some reason a path is not active set the first to active by default
      setIsActiveMap(checkAllFalseAndSetFirstTrue(newIsActiveMap));
    }
  };

  const isActivePath = (link: NavigationLinkWithSubLinks) => {
    return isActiveMap[link.to || link.name];
  };

  return (
    <ActivePathContext.Provider
      value={{
        isActivePath,
        makePathActive,
      }}
    >
      {children}
    </ActivePathContext.Provider>
  );
};

export const useActivePath = (): ActivePathState => {
  const context = useContext<ActivePathState | undefined>(ActivePathContext);
  if (!context) {
    throw new Error("useActivePath must be used within a ActivePathContextProvider");
  }
  return context;
};
