import styled from "styled-components";

type NavbarContainerProps = {
  top: number;
  zIndex?: number;
};

export const NavbarContainer = styled.div<NavbarContainerProps>`
  height: calc(100vh - ${({ top }) => top}px);
  background: ${(props) => props.theme.nav.vertical.nav.background};
  display: flex;
  flex-direction: column;
  min-width: 36px;
  position: fixed;
  top: ${({ top }) => top}px;
  left: 0;
  ${(props) => (props.zIndex ? "z-index: " + props.zIndex : undefined)};
`;
