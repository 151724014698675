import React, { AnchorHTMLAttributes } from "react";
import { NavigationLinkWithSubLinks } from "../../../../../../types";
import NavItem from "../../NavItem/NavItem";
import NavMenu from "../../NavMenu/NavMenu";
import { LinksContainerStyled, PermissionsContainer } from "./LinksContainerStyles";

type LinksContainerProps = {
  links: NavigationLinkWithSubLinks[];
  closeMenu: () => void;
  displayEnvIndicator: boolean;
  displayPermissionsIndicator: boolean;
  displayStatusIndicator: boolean;
  permissions?: string;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
};

const LinksContainer: React.FC<LinksContainerProps> = ({
  links,
  closeMenu,
  displayEnvIndicator,
  displayPermissionsIndicator,
  displayStatusIndicator,
  permissions = "",
  customLinkComponent,
}) => {
  return (
    <LinksContainerStyled displayEnvIndicator={displayEnvIndicator} displayStatusIndicator={displayStatusIndicator}>
      {displayPermissionsIndicator && !!permissions?.length && (
        <PermissionsContainer>{permissions}</PermissionsContainer>
      )}
      {links.map((link: NavigationLinkWithSubLinks) => {
        if (link.subLinks?.length) {
          return (
            <NavMenu
              {...link}
              onClick={() => {
                if (!!link.onClick) {
                  link.onClick();
                }
                closeMenu();
              }}
              customLinkComponent={customLinkComponent}
              key={link.name}
            />
          );
        } else {
          return (
            <NavItem
              {...link}
              onClick={() => {
                if (!!link.onClick) {
                  link.onClick();
                }
                closeMenu();
              }}
              customLinkComponent={customLinkComponent}
              key={link.name}
            />
          );
        }
      })}
    </LinksContainerStyled>
  );
};

export default LinksContainer;
