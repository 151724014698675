import React from "react";
import Loading from "./Loading";
import { useLoading } from "./LoadingContext";

const LoadingFromProvider: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { loading, type, animated, label, blocking } = useLoading();
  return loading && blocking ? <Loading type={type} animated={animated} label={label} /> : null;
};

export default LoadingFromProvider;
