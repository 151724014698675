import styled from "styled-components";

type LinksContainerStyleProps = {
  displayEnvIndicator: boolean;
  displayStatusIndicator: boolean;
};

const calculateHeight = (displayEnvIndicator: boolean, displayStatusIndicator: boolean): number => {
  let height = 45;
  if (displayEnvIndicator) {
    height += 18;
  }
  if (displayStatusIndicator) {
    height += 18;
  }
  return height;
};

export const LinksContainerStyled = styled.ul<LinksContainerStyleProps>`
  list-style: none;
  padding: 0;
  margin: ${(props) => (props.displayStatusIndicator ? "63px 0 0" : "45px 0 0")};
  height: ${(props) => `calc(100vh - ${calculateHeight(props.displayEnvIndicator, props.displayStatusIndicator)}px)`};
  width: 100%;
  z-index: 1051;
  overflow-y: scroll;
  background: ${(props) => props.theme.nav.mobile.menu.background};
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const PermissionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem 2.5rem 0.5rem 2.5rem;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.nav.fontFamily};
  color: ${(props) => props.theme.nav.mobile.permissions.color};
  background: ${(props) => props.theme.nav.mobile.permissions.background};
  border-bottom: 1px solid ${(props) => props.theme.nav.mobile.permissions.borderColor};
`;
