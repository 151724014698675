import React from "react";
import { LinkProps as RRLinkProps } from "react-router-dom";
import { LinkThemeType } from "types";
import { useTheme } from "../../hooks";
import { LinkStyled } from "./LinkStyles";

type LinkProps = RRLinkProps & {
  /** Styles to override */
  styles?: LinkThemeType;
};

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: LinkThemeType = { ...Theme.link, ...styles };

  return <LinkStyled styles={StylesOverride} {...rest} />;
};

export default Link;
