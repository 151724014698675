import styled from "styled-components";

export const VerticalNavContainer = styled.div`
  display: flex;
`;

export const BrandHolder = styled.ul`
  padding: 0.5rem 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
`;
