import { ColorType, ThemeType } from "../types";

export const CoolColors: ColorType = {
  snow: "#fafbfc",
  cloud: "#f5f7fa",
  fog: "#dee5ed",
  "blue-grey": "#92a5ba",
  slate: "#77879a",
  "pale-blue": "#e5e6e8",
  "mlb-navy": "#041e42",
  "mlb-blue": "#002c73",
  "mlb-red": "#bf0d3e",
  "mlb-red-hover": "#8c0128",
  "mlb-secondary-blue": "#04152c",
  white: "#ffffff",
  "white-hover": "#e9f2ff",
  black: "#000000",
  green: "#00a651",
  "green-hover": "#218838",
  blue: "#4c8cee",
  "blue-hover": "#1e68d9",
  "almost-white": "#f7f7f7",
  "light-grey": "#e5e6e8",
  grey: "#d2d2d2",
  "dark-grey": "#bbbbbb",
  charcoal: "#666666",
  "almost-black": "#212121",
  orange: "#ff9300",
  yellow: "#fed105",
  "powder-blue": "#afccfa",
};

const FontWeights = {
  light: "100",
  normal: "400",
  bold: "600",
};

const Cool: ThemeType = {
  name: "COOL",
  background: CoolColors.fog,
  fontFamily: '"Helvetica Neue", helvetica, arial, sans-serif',
  colors: CoolColors,
  label: {
    color: CoolColors["blue-grey"],
    "font-weight": "normal",
    "asterisk-color": CoolColors["mlb-red"],
    size: "0.75rem",
    display: "flex",
    "asterisk-margin": "0rem 0rem 0rem 0.3rem",
  },
  placeholder: {
    color: CoolColors["blue-grey"],
    "font-weight": 300,
    "font-style": "italic",
  },
  input: {
    color: CoolColors["black"],
    height: "auto",
    "border-color": CoolColors["fog"],
    size: "0.875rem",
    background: CoolColors["white"],
    required: {
      color: CoolColors["mlb-blue"],
      background: CoolColors["white"],
      "border-color": CoolColors["mlb-blue"],
    },
    missed: {
      color: CoolColors["mlb-red"],
      background: CoolColors["white"],
      "border-color": CoolColors["mlb-red"],
    },
    disabled: {
      color: CoolColors["black"],
      background: CoolColors["snow"],
      "border-color": CoolColors["fog"],
    },
    adorner: {
      background: CoolColors["white"],
    },
  },
  autocomplete: {
    color: CoolColors["black"],
    "border-color": CoolColors["fog"],
    size: "0.875rem",
    background: CoolColors["white"],
    required: {
      color: CoolColors["mlb-blue"],
      background: CoolColors["white"],
      "border-color": CoolColors["mlb-blue"],
    },
    missed: {
      color: CoolColors["mlb-red"],
      background: CoolColors["white"],
      "border-color": CoolColors["mlb-red"],
    },
    disabled: {
      color: CoolColors["black"],
      background: CoolColors["snow"],
      "border-color": CoolColors["fog"],
    },
  },
  validation: {
    error: {
      color: "#BF0D3E",
    },
    success: {
      color: CoolColors.green,
    },
  },
  icon: {
    color: CoolColors["blue"],
    hoverColor: CoolColors["mlb-blue"],
    size: "1.1rem",
    disabledColor: CoolColors.fog,
  },
  iconAdorner: {
    color: CoolColors["blue"],
    hoverColor: CoolColors["mlb-blue"],
    size: "1.1rem",
  },
  radio: {
    color: CoolColors["blue-grey"],
    "font-weight": "bold",
  },
  button: {
    "border-radius": "0.375rem",
    size: "0.875rem",
    padding: "0.3125rem .75rem",
    "line-height": "1.5",
    "font-weight": FontWeights["bold"],
    primary: {
      "background-color": CoolColors["blue"],
      "border-color": CoolColors["blue"],
      color: CoolColors["white"],
      hover: CoolColors["blue-hover"],
    },
    secondary: {
      "background-color": CoolColors["white"],
      "border-color": CoolColors["blue"],
      color: CoolColors["blue"],
      hover: CoolColors["white-hover"],
    },
    danger: {
      "background-color": CoolColors["mlb-red"],
      "border-color": CoolColors["mlb-red"],
      color: CoolColors["white"],
      hover: CoolColors["mlb-red-hover"],
    },
    default: {
      "background-color": CoolColors["almost-white"],
      "border-color": CoolColors["almost-white"],
      color: CoolColors["charcoal"],
      hover: CoolColors["grey"],
    },
    success: {
      "background-color": CoolColors["green"],
      "border-color": CoolColors["green"],
      color: CoolColors["white"],
      hover: CoolColors["green-hover"],
    },
    disabled: {
      "background-color": CoolColors["cloud"],
      "border-color": CoolColors["fog"],
      color: CoolColors["fog"],
      opacity: 1,
    },
    loading: {
      "background-color": CoolColors["cloud"],
      "border-color": CoolColors["fog"],
      color: CoolColors["blue"],
    },
    small: {
      size: "0.6875rem",
      padding: "0.25rem .75rem",
    },
    large: {
      size: "1.125rem",
      padding: "0.25rem 1rem",
    },
    spinner: {
      size: 15,
    },
  },
  link: {
    color: CoolColors["blue"],
    size: "0.875rem",
    hover: CoolColors["blue-hover"],
    disabled: CoolColors["fog"],
    opacity: 1,
    textDecoration: "none",
  },
  checkbox: {
    color: CoolColors["blue"],
    labelColor: CoolColors["almost-black"],
    background: CoolColors["white"],
    borderColor: CoolColors["pale-blue"],
    disabledColor: CoolColors["grey"],
    disabledBackgroundColor: CoolColors["almost-white"],
    margin: "0 0 0 0.375rem",
    top: "5px",
  },
  collapse: {
    header: {
      color: CoolColors["mlb-blue"],
      "font-size": "1rem",
      background: CoolColors["white"],
      border: "none",
      padding: 0,
    },
    body: {
      color: CoolColors["black"],
      "font-size": "1rem",
      background: CoolColors["white"],
      border: "none",
      padding: 0,
    },
  },
  modal: {
    backdrop: CoolColors["blue-grey"],
    backdropOpacity: 0.75,
    border: `1px solid ${CoolColors["blue-grey"]}`,
    header: {
      background: CoolColors["fog"],
      fontColor: CoolColors["mlb-navy"],
      bottomBorderColor: CoolColors["blue-grey"],
      closeColor: CoolColors["blue"],
      padding: "1rem 1rem",
      fontWeight: "bold",
      fontSize: "1.3125rem",
      fontStyle: "normal",
    },
    body: {
      background: CoolColors["white"],
      fontColor: CoolColors["slate"],
      padding: "1rem",
    },
    footer: {
      background: CoolColors["fog"],
      fontColor: CoolColors["black"],
      borderTopColor: CoolColors["blue-grey"],
      padding: "1rem 1rem",
    },
  },
  popover: {
    backdrop: CoolColors["blue-grey"],
    backdropOpacity: 0.75,
    border: `1px solid ${CoolColors["blue-grey"]}`,
    header: {
      background: CoolColors["fog"],
      fontColor: CoolColors["mlb-navy"],
      bottomBorderColor: CoolColors["blue-grey"],
      closeColor: CoolColors["blue"],
      padding: ".5rem 1rem",
      fontWeight: "bold",
      fontSize: "1rem",
      fontStyle: "normal",
    },
    body: {
      background: CoolColors["white"],
      fontColor: CoolColors["slate"],
      padding: "1rem",
    },
    footer: {
      background: CoolColors["fog"],
      fontColor: CoolColors["black"],
      borderTopColor: CoolColors["blue-grey"],
      padding: ".5rem 1rem",
    },
  },
  tooltip: {
    background: CoolColors["mlb-navy"],
    color: CoolColors["white"],
  },
  card: {
    header: {
      backgroundColor: CoolColors["white"],
      color: CoolColors["mlb-blue"],
      borderBottom: "1px solid " + CoolColors["mlb-blue"],
      fontSize: "1.1rem",
      padding: "0.25rem 1.25rem",
      mobilePadding: "0.45rem 8px",
    },
    body: {
      border: "none",
      background: CoolColors["snow"],
      padding: "0.45rem",
      mobilePadding: "0.45rem",
    },
    footer: {
      background: CoolColors["white"],
      borderColor: CoolColors["grey"],
    },
    borderRadius: "0.25rem",
  },
  alert: {
    padding: "0.75rem 1.25rem",
    margin: "0 0 1rem 0",
    fontSize: "1rem",
    borderRadius: "0.25rem",
    success: {
      background: "#c2f3da",
      color: CoolColors.green,
      border: CoolColors.green,
    },
    info: {
      background: "#bdd7ff",
      color: "#3575d8",
      border: "#3575d8",
    },
    danger: {
      background: "#f9ceda",
      color: CoolColors["mlb-red"],
      border: CoolColors["mlb-red"],
    },
    warning: {
      background: "#fcefb1",
      color: CoolColors.orange,
      border: CoolColors.orange,
    },
  },
  section: {
    header: {
      color: CoolColors["mlb-blue"],
      "font-size": "1rem",
      border: `1px solid ${CoolColors["fog"]}; border-left: none; border-right: none; border-top: none;`,
      background: "inherit",
      padding: "0",
    },
    body: {
      color: CoolColors["black"],
      "font-size": "1rem",
      background: "inherit",
      border: "inherit",
      padding: "0",
    },
  },
  subSection: {
    background: CoolColors["fog"],
    borderBottom: "",
    border: "; border-radius: 4px;",
    color: CoolColors["mlb-blue"],
    padding: "0.25rem .5rem",
  },
  textarea: {
    placeholder: {
      fontWeight: 300,
      color: CoolColors["blue-grey"],
      fontStyle: "italic",
    },
    input: {
      borderColor: CoolColors["fog"],
      color: CoolColors["black"],
      fontWeight: 300,
      background: CoolColors["white"],
      required: {
        color: CoolColors["mlb-blue"],
        background: CoolColors["white"],
        borderColor: CoolColors["mlb-blue"],
      },
      missed: {
        color: CoolColors["mlb-red"],
        background: CoolColors["white"],
        borderColor: CoolColors["mlb-red"],
      },
      disabled: {
        color: CoolColors["black"],
        background: CoolColors["snow"],
        borderColor: CoolColors["fog"],
      },
    },
  },
  environment: {
    local: {
      background: CoolColors["green"],
    },
    dev: {
      background: CoolColors["mlb-red"],
    },
    stg: {
      background: CoolColors.yellow,
    },
    qa: {
      background: CoolColors.blue,
    },
    prod: {
      background: CoolColors["mlb-blue"],
    },
  },
  stickyFooter: {
    background: CoolColors.white,
    color: CoolColors.blue,
  },
  navigation: {
    horizontal: {
      color: CoolColors["white"],
      background: CoolColors["mlb-navy"],
      size: "2.5rem",
      borderColor: CoolColors["mlb-navy"],
      signout: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["white"],
        borderBottomColor: CoolColors["mlb-navy"],
      },
      active: {
        color: CoolColors["white"],
        background: CoolColors["mlb-secondary-blue"],
      },
      hover: {
        color: CoolColors["white"],
        background: CoolColors["mlb-secondary-blue"],
      },
      topBar: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["white"],
      },
      usernameDropdown: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["white"],
        hover: {
          color: CoolColors["mlb-navy"],
          background: CoolColors["white"],
        },
      },
      mobile: {
        background: CoolColors["mlb-navy"],
        size: "2.7rem",
        linkColor: CoolColors.white,
        linkBackground: CoolColors["mlb-navy"],
        sublinkColor: CoolColors.white,
        sublinkBackground: CoolColors["mlb-secondary-blue"],
        dropdownColor: CoolColors.white,
        dropdownBackground: CoolColors["mlb-secondary-blue"],
        permissionsBackground: CoolColors["mlb-navy"],
        permissionsColor: CoolColors.white,
      },
      rightLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      leftLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      centerLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      statuses: {
        success: {
          background: "#c2f3da",
          color: CoolColors.green,
          border: CoolColors.green,
        },
        info: {
          background: "#bdd7ff",
          color: "#3575d8",
          border: "#3575d8",
        },
        danger: {
          background: "#f9ceda",
          color: CoolColors["mlb-red"],
          border: CoolColors["mlb-red"],
        },
        warning: {
          background: "#fcefb1",
          color: CoolColors.orange,
          border: CoolColors.orange,
        },
      },
      sublinks: {
        color: CoolColors["white"],
        background: CoolColors["mlb-navy"],
        hover: {
          color: CoolColors["white"],
          background: CoolColors["mlb-navy"],
        },
      },
    },
    vertical: {
      color: CoolColors["white"],
      background: CoolColors["mlb-navy"],
      size: "2.5rem",
      borderColor: CoolColors["mlb-navy"],
      signout: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["mlb-navy"],
        borderBottomColor: CoolColors["mlb-navy"],
      },
      usernameDropdown: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["white"],
        hover: {
          color: CoolColors["mlb-navy"],
          background: CoolColors["white"],
        },
      },
      active: {
        color: CoolColors["white"],
        background: CoolColors["mlb-secondary-blue"],
      },
      hover: {
        color: CoolColors["mlb-blue"],
        background: CoolColors["mlb-secondary-blue"],
      },
      topBar: {
        color: CoolColors["mlb-navy"],
        background: CoolColors["white"],
      },
      mobile: {
        background: CoolColors["mlb-navy"],
        size: "2.7rem",
        linkColor: CoolColors.white,
        linkBackground: CoolColors["mlb-navy"],
        sublinkColor: CoolColors.white,
        sublinkBackground: CoolColors["mlb-secondary-blue"],
        dropdownColor: CoolColors.white,
        dropdownBackground: CoolColors["mlb-secondary-blue"],
        permissionsBackground: CoolColors["mlb-navy"],
        permissionsColor: CoolColors.white,
      },
      rightLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      leftLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      centerLogo: {
        height: "37px",
        width: "70px",
        margin: "",
      },
      statuses: {
        success: {
          background: "#c2f3da",
          color: CoolColors.green,
          border: CoolColors.green,
        },
        info: {
          background: "#bdd7ff",
          color: "#3575d8",
          border: "#3575d8",
        },
        danger: {
          background: "#f9ceda",
          color: CoolColors["mlb-red"],
          border: CoolColors["mlb-red"],
        },
        warning: {
          background: "#fcefb1",
          color: CoolColors.orange,
          border: CoolColors.orange,
        },
      },
      sublinks: {
        color: CoolColors["white"],
        background: CoolColors["mlb-navy"],
        hover: {
          color: CoolColors["white"],
          background: CoolColors["mlb-navy"],
        },
      },
    },
  },
  select: {
    placeholder: {
      fontWeight: 300,
      color: CoolColors["blue-grey"],
      fontStyle: "italic",
    },
    hover: {
      color: CoolColors.white,
      background: CoolColors.blue,
    },
    background: CoolColors.white,
    color: CoolColors["black"],
    borderRadius: "4px",
    borderColor: CoolColors["fog"],
    multiBackground: CoolColors["fog"],
    multiBorderRadius: "2px",
    actionColor: CoolColors["blue"],
    required: {
      color: CoolColors["mlb-blue"],
      background: CoolColors["white"],
      borderColor: CoolColors["mlb-blue"],
    },
    disabled: {
      background: CoolColors.snow,
    },
    clearIndicator: {
      padding: "8px",
    },
  },
  datepicker: {
    input: {
      actionColor: CoolColors.blue,
      actionHoverColor: CoolColors.blue,
      color: CoolColors["black"],
      background: CoolColors["white"],
      disabledBackground: CoolColors["almost-white"],
    },
    calendar: {
      borderColor: CoolColors["grey"],
      header: {
        background: "#f0f0f0",
        color: CoolColors["black"],
        borderColor: CoolColors["grey"],
        select: {
          background: "#e9e9ed",
          color: CoolColors["black"],
        },
        dayOfWeek: {
          color: CoolColors["black"],
        },
      },
      body: {
        background: CoolColors["white"],
        color: CoolColors["black"],
        borderColor: CoolColors["grey"],
        day: {
          color: CoolColors["black"],
          colorOutsideMonth: CoolColors["grey"],
          active: {
            color: CoolColors["white"],
            background: "#216ba5",
            borderColor: "#216ba5",
          },
          disabled: {
            color: CoolColors["grey"],
            background: CoolColors["white"],
            borderColor: CoolColors["white"],
          },
          hover: {
            background: "#f0f0f0",
          },
        },
      },
    },
  },
  accordion: {
    action: {
      color: CoolColors.charcoal,
      size: "1.5rem",
      padding: "0 1.25rem",
      margin: "0.375rem 0",
    },
    header: {
      color: CoolColors["black"],
      "font-size": "1rem",
      background: CoolColors["fog"],
      border: "1px solid rgba(0, 0, 0, 0.125)",
      padding: "0.75rem",
    },
    body: {
      color: CoolColors["black"],
      "font-size": "1rem",
      background: CoolColors["white"],
      border: "1px solid rgba(0, 0, 0, 0.125)",
      padding: "0.75rem",
    },
  },
  stepper: {
    size: "30px",
    activeBorderColor: CoolColors["blue"],
    complete: {
      color: CoolColors["white"],
      background: CoolColors["mlb-blue"],
      borderColor: CoolColors["mlb-blue"],
      labelColor: CoolColors["mlb-navy"],
    },
    incomplete: {
      color: CoolColors["mlb-blue"],
      background: CoolColors.white,
      borderColor: CoolColors["mlb-blue"],
      labelColor: CoolColors["mlb-navy"],
    },
    disabled: {
      color: CoolColors["blue-grey"],
      background: CoolColors.fog,
      borderColor: CoolColors["blue-grey"],
      labelColor: CoolColors["blue-grey"],
    },
    spacerColor: CoolColors["grey"],
    spacerWidth: "2px",
  },
  table: {
    table: {
      fontWeight: 100,
      fontSize: "0.875rem",
    },
    th: {
      color: CoolColors["black"],
      background: CoolColors["fog"],
      fontWeight: 700,
    },
    td: {
      color: CoolColors["black"],
      background: CoolColors["white"],
      fontWeight: 400,
    },
    tdInfo: {
      color: CoolColors["black"],
      background: CoolColors["dark-grey"],
      fontWeight: "bold",
      width: "150px",
    },
  },
  verticalTable: {
    table: {
      fontWeight: 100,
      fontSize: "0.875rem",
    },
    th: {
      color: CoolColors["black"],
      background: CoolColors["fog"],
      fontWeight: 700,
      width: "150px",
    },
    td: {
      color: CoolColors["black"],
      background: CoolColors["white"],
      fontWeight: 400,
    },
    tdInfo: {
      color: CoolColors["black"],
      background: CoolColors["powder-blue"],
      fontWeight: "bold",
      width: "150px",
    },
  },
  datatable: {
    datatable: {
      border: ``,
    },
    scrollBar: {
      background: CoolColors.fog,
      element: {
        color: CoolColors.white,
        borderColor: CoolColors.fog,
      },
    },
    subrow: {
      icon: {
        color: CoolColors["blue"],
        hoverColor: CoolColors["mlb-blue"],
        disabledColor: CoolColors.fog,
      },
    },
    moreActions: {
      icon: {
        color: CoolColors["blue"],
        hoverColor: CoolColors["mlb-blue"],
        disabledColor: CoolColors.fog,
      },
      popoverAction: {
        color: CoolColors.blue,
        fontSize: "14px",
        hoverBackgroundColor: CoolColors["pale-blue"],
        padding: "8px",
        borderColor: CoolColors["blue"],
      },
      addRowText: {
        color: CoolColors["blue"],
      },
    },
    expanded: {
      icon: {
        color: CoolColors["blue"],
        hoverColor: CoolColors["mlb-blue"],
        disabledColor: CoolColors.fog,
      },
    },
    sortIcon: {
      color: CoolColors["mlb-red"],
      hoverColor: CoolColors["mlb-red-hover"],
      size: "1.1rem",
    },
    header: {
      background: CoolColors["fog"],
      color: CoolColors["mlb-blue"],
      borderBottomColor: CoolColors["light-grey"],
      fontWeight: 700,
    },
    row: {
      background: CoolColors["white"],
      selectedBackground: CoolColors.cloud,
      color: CoolColors.black,
      separatorColor: CoolColors.grey,
      disabledBackground: CoolColors["grey"],
      expandedBackground: CoolColors["almost-white"],
    },
    column: {
      background: CoolColors.white,
      sortedBackground: "none",
      color: CoolColors.black,
    },
    pagination: {
      color: CoolColors.blue,
      background: CoolColors.white,
      active: {
        color: CoolColors.white,
        background: CoolColors.blue,
      },
      disabled: {
        color: CoolColors.fog,
        background: CoolColors.white,
      },
    },
  },
  heroImage: {
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderBottom: "none",
    opacity: 0.6,
  },
  loading: {
    fontSize: "1rem",
    color: CoolColors.black,
    background: CoolColors.fog,
    opacity: 0.7,
  },
  hint: {
    fontWeight: 300,
    fontSize: ".75rem",
    color: CoolColors.slate,
  },
  danger: {
    fontWeight: 300,
    fontSize: ".75rem",
    color: CoolColors["mlb-red"],
  },
  wysiwyg: {
    background: CoolColors.white,
    editorToolbarBackground: CoolColors["fog"],
    editorToolbarBorderRadius: "4px",
    fontSize: "14px",
    border: `solid 1px ${CoolColors["fog"]}`,
    editorMinHeight: "120px",
    editorPaddingTop: "5px",
    editorPaddingLeft: "10px",
    historyWrapperColor: "#ff",
    textAlignWrapperColor: `${CoolColors["fog"]}`,
    textAlignMarginRight: "2px",
    textAlignWrapperHeight: "30px",
    optionWrapperBackground: CoolColors["cloud"],
    optionWrapperHeight: "30px",
    listWrapperColor: `${CoolColors["fog"]}`,
    activeOptionColor: "#d8d8d8",
    footerHeight: "25px",
    footerBackground: CoolColors["fog"],
    footerBorderRadius: "4px",
  },
  tabSwitcher: {
    container: {
      background: CoolColors["fog"],
    },
    tab: {
      backgroundColor: CoolColors.snow,
      firstChildBorderColor: CoolColors["pale-blue"],
    },
    indicator: {
      color: CoolColors["almost-black"],
    },
    componentContainer: {
      backgroundColor: CoolColors.snow,
      borderColor: CoolColors["fog"],
    },
  },
  fileAttachment: {
    dropArea: {
      borderColor: CoolColors["blue-grey"],
      activeBorderColor: CoolColors.blue,
    },
    dropAreaText: {
      color: CoolColors["blue-grey"],
    },
    label: {
      backgroundColor: CoolColors["light-grey"],
      border: CoolColors["grey"],
    },
  },
  typography: {
    span: {
      size: ".9rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    div: {
      size: ".9rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body1: {
      size: ".9rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body2: {
      size: "1rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body3: {
      size: "1.1rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    caption: {
      size: ".9rem",
      color: CoolColors.black,
      weight: 400,
      style: "italic",
      width: "100%",
    },
    h1: {
      size: "6rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h2: {
      size: "3.75rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h3: {
      size: "3rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h4: {
      size: "2.125rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h5: {
      size: "1.5rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h6: {
      size: "1.25rem",
      color: CoolColors.black,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    subtitle1: {
      size: ".8rem",
      color: CoolColors.black,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    subtitle2: {
      size: ".8rem",
      color: CoolColors.black,
      weight: 400,
      style: "italic",
      width: "100%",
    },
  },
  hr: {
    color: CoolColors.fog,
  },
  paper: {
    borderColor: CoolColors.fog,
    background: CoolColors.white,
  },
  toggleButtons: {
    background: CoolColors.white,
    border: `1px solid ${CoolColors.fog}`,
    buttonBackgroundColor: CoolColors.white,
    buttonActiveColor: CoolColors.blue,
  },
  calendar: {
    header: {
      color: CoolColors.black,
      background: CoolColors["light-grey"],
      fontFamily: "Roboto",
      fontWeight: "normal",
      size: "0.6875rem",
    },
    body: {
      color: CoolColors.black,
      background: CoolColors.white,
      borderColor: CoolColors["light-grey"],
      fontFamily: "RobotoSlab",
      size: "1.125rem",
    },
    actions: {
      background: CoolColors.white,
      color: CoolColors.black,
      activeColor: "#71c4fa",
    },
  },
  progress: {
    primary: {
      color: CoolColors.blue,
    },
    secondary: {
      color: CoolColors.grey,
    },
    success: {
      color: CoolColors.green,
    },
    error: {
      color: CoolColors["mlb-red"],
    },
  },
  statusDrawer: {
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.5)",
    header: {
      borderRadius: "10px 10px 0 0",
      background: CoolColors["mlb-blue"],
      color: CoolColors.white,
    },
    body: {
      background: CoolColors.white,
      borderColor: CoolColors["light-grey"],
    },
  },
  nav: {
    fontFamily: '"Helvetica Neue", helvetica, arial, sans-serif',
    horizontal: {
      nav: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
        hover: {
          color: CoolColors.white,
          background: CoolColors["mlb-blue"],
        },
        active: {
          color: CoolColors.white,
          background: CoolColors["mlb-blue"],
        },
      },
      username: {
        background: CoolColors.white,
        color: CoolColors["mlb-navy"],
        links: {
          background: CoolColors["mlb-navy"],
          color: CoolColors.white,
          hover: {
            color: CoolColors.white,
            background: CoolColors["mlb-blue"],
          },
        },
      },
    },
    vertical: {
      nav: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
        hover: {
          color: CoolColors.white,
          background: CoolColors["mlb-blue"],
        },
        active: {
          color: CoolColors.white,
          background: CoolColors["mlb-blue"],
        },
      },
      username: {
        background: CoolColors.white,
        color: CoolColors["mlb-navy"],
        links: {
          background: CoolColors["mlb-navy"],
          color: CoolColors.white,
          hover: {
            color: CoolColors.white,
            background: CoolColors["mlb-blue"],
          },
        },
      },
    },
    mobile: {
      nav: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
      },
      menu: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
        link: {
          background: CoolColors["mlb-navy"],
          color: CoolColors.white,
        },
      },
      permissions: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
        borderColor: CoolColors["mlb-blue"],
      },
      link: {
        background: CoolColors["mlb-navy"],
        color: CoolColors.white,
        borderColor: CoolColors["mlb-blue"],
      },
      active: {
        background: CoolColors["mlb-blue"],
        color: CoolColors.white,
      },
      hover: {
        background: CoolColors["mlb-blue"],
        color: CoolColors.white,
      },
    },
  },
  breakpoints: {
    mobile: {
      maxWidth: 767,
    },
    tablet: {
      minWidth: 768,
      maxWidth: 991,
    },
    desktop: {
      minWidth: 992,
    },
    desktopTablet: {
      minWidth: 768,
    },
    tabletMobile: {
      maxWidth: 991,
    },
  },
  actionCard: {
    background: CoolColors.white,
    border: `1px solid ${CoolColors.grey}`,
  },
  chip: {
    default: {
      background: CoolColors["almost-white"],
      border: CoolColors["almost-white"],
      color: CoolColors["charcoal"],
      hover: CoolColors["grey"],
    },
    primary: {
      background: CoolColors["blue"],
      border: CoolColors["blue"],
      color: CoolColors["white"],
      hover: CoolColors["blue-hover"],
    },
    secondary: {
      background: CoolColors["white"],
      border: CoolColors["blue"],
      color: CoolColors["blue"],
      hover: CoolColors["white-hover"],
    },
    success: {
      background: CoolColors["green"],
      border: CoolColors["green"],
      color: CoolColors["white"],
      hover: CoolColors["green-hover"],
    },
    danger: {
      background: CoolColors["mlb-red"],
      border: CoolColors["mlb-red"],
      color: CoolColors["white"],
      hover: CoolColors["mlb-red-hover"],
    },
    warning: {
      background: "#fcefb1",
      border: CoolColors.orange,
      color: CoolColors.orange,
      hover: "#f5df78",
    },
    disabled: {
      hover: CoolColors["cloud"],
      background: CoolColors["cloud"],
      border: CoolColors["fog"],
      color: CoolColors["fog"],
    },
  },
};

export default Cool;
