import styled from "styled-components";

type StatusHolderProps = {
  displayEnvIndicator: boolean;
};

export const StatusHolder = styled.div<StatusHolderProps>`
  position: fixed;
  top: ${(props) => (props.displayEnvIndicator ? 63 : 45)}px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1051;
`;
