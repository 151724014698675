import React, { AnchorHTMLAttributes, useEffect, useState } from "react";
import { NavigationConfigType, NavigationLinkType, NavigationLinkWithSubLinks } from "../../../../types";
import Brand from "../shared/brand/Brand";
import Nav from "../shared/nav/Nav";
import { EndComponentHolder, MobileNavigationContainer, MobileNavigationSpacer } from "./MobileNavigationStyles";
import { EnvIndicator } from "./env";
import LinksContainer from "./links/container/LinksContainer";
import NavHamburger from "./navHamburger/NavHamburger";
import Navbar from "./navbar/Navbar";
import QuickLinks from "./quicklink/QuickLinks";
import MobileNavStatus from "./status/MobileNavStatus";

interface MobileNavigationProps {
  config: NavigationConfigType;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const MobileNavigation = ({ config, customLinkComponent }: MobileNavigationProps) => {
  const {
    loggedIn,
    username,
    centerLogo,
    endLogo,
    endComponent,
    navigationLinks = [],
    usernameDropdownLinks = [],
    env = "prod",
    displayEnvIndicator = false,
    displayPermissionsIndicator = false,
    permissions,
    status,
  } = config;
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [quickLinks, setQuickLinks] = useState<NavigationLinkType[]>([]);
  const [mobileLinks, setMobileLinks] = useState<NavigationLinkWithSubLinks[]>([]);

  useEffect(() => {
    const links: NavigationLinkWithSubLinks[] = navigationLinks.filter(
      (link: NavigationLinkType) => link.visible !== false,
    );
    if (!!username && !!usernameDropdownLinks?.length) {
      links.push({
        name: username,
        subLinks: usernameDropdownLinks.filter((link: NavigationLinkType) => link.visible !== false),
      });
    }
    setMobileLinks(links);
  }, [navigationLinks, usernameDropdownLinks]);

  useEffect(() => {
    const qls = navigationLinks
      .map((link: NavigationLinkWithSubLinks) => {
        const links: NavigationLinkWithSubLinks[] = [link];
        return !!link.subLinks ? links.concat(link.subLinks.filter((sub) => sub.quickLink)) : links;
      })
      .reduce((a: NavigationLinkWithSubLinks[], b: NavigationLinkWithSubLinks[]) => a.concat(b), [])
      .filter((link: NavigationLinkWithSubLinks) => !!link.quickLink && link.visible);

    setQuickLinks(qls.slice(0, 4));
  }, [navigationLinks]);

  return (
    <>
      <MobileNavigationContainer>
        {displayEnvIndicator && <EnvIndicator env={env} />}
        <Navbar displayEnvIndicator={displayEnvIndicator}>
          {loggedIn && (
            <>
              <NavHamburger open={navOpen} toggle={setNavOpen} />
              <Nav>
                <QuickLinks links={quickLinks} customLinkComponent={customLinkComponent} />
              </Nav>
            </>
          )}
          {!!centerLogo && (
            <Nav align="center">
              <Brand
                logo={centerLogo.logo}
                text={centerLogo.text}
                link={centerLogo.link}
                contentHeight={centerLogo!.logoHeight}
                customLinkComponent={customLinkComponent}
              />
            </Nav>
          )}
          {!!endLogo && (
            <Nav align="end">
              <Brand
                logo={endLogo.logo}
                text={endLogo.text}
                link={endLogo.link}
                contentHeight={endLogo!.logoHeight}
                customLinkComponent={customLinkComponent}
              />
            </Nav>
          )}
          {loggedIn && (
            <>
              {!!endComponent && (
                <Nav>
                  <EndComponentHolder>{endComponent}</EndComponentHolder>
                </Nav>
              )}
            </>
          )}
        </Navbar>
        {navOpen && (
          <LinksContainer
            links={mobileLinks}
            closeMenu={() => setNavOpen(false)}
            permissions={permissions}
            displayEnvIndicator={displayEnvIndicator}
            displayPermissionsIndicator={displayPermissionsIndicator}
            displayStatusIndicator={loggedIn && !!status?.text?.length}
            customLinkComponent={customLinkComponent}
          />
        )}
      </MobileNavigationContainer>
      {loggedIn && <MobileNavStatus status={status} displayEnvIndicator={displayEnvIndicator} />}
      <MobileNavigationSpacer
        displayEnvIndicator={displayEnvIndicator}
        hasStatus={loggedIn && !!status?.text?.length}
      />
    </>
  );
};

export default MobileNavigation;
