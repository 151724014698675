import styled from "styled-components";

type NavbarStylesProps = {
  loggedIn: boolean;
};

export const NavbarContainer = styled.nav<NavbarStylesProps>`
  background: ${(props) => props.theme.nav.horizontal.nav.background};
  height: 50px;
  width: 100%;
  position: fixed;
  top: ${(props) => (props.loggedIn ? "20px" : 0)};
  left: 0;
  z-index: 1051;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`;
