import styled from "styled-components";

type BrandContainerProps = {
  height?: number;
  isVertical?: boolean;
};

export const BrandContainer = styled.li<BrandContainerProps>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.nav[props.isVertical ? "vertical" : "horizontal"].nav.color};
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  height: ${(props) => props.height + "px" ?? "40px"};
  line-height: ${(props) => (props.height ? props.height - 8 + "px" : "32px")};
`;
