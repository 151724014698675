import React, { AnchorHTMLAttributes } from "react";
import { useTheme } from "../../../../../hooks";
import { Link } from "../../../../links";

interface CustomLinkProps {
  to: string;
  hovered?: boolean;
  active?: boolean;
  isVertical?: boolean;
  children?: React.ReactNode;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const CustomLink = ({ children, to, hovered, active, isVertical, customLinkComponent }: CustomLinkProps) => {
  // hooks
  const { Theme } = useTheme();

  if (!!customLinkComponent) {
    const LinkComponent = customLinkComponent;
    return (
      <LinkComponent
        href={to}
        style={{
          ...Theme.link,
          color: hovered
            ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.hover.color
            : active
            ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.active.color
            : Theme.nav[isVertical ? "vertical" : "horizontal"].nav.color,
          fontSize: "1rem",
        }}
      >
        {children}
      </LinkComponent>
    );
  }

  return (
    <Link
      to={to}
      styles={{
        ...Theme.link,
        color: hovered
          ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.hover.color
          : active
          ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.active.color
          : Theme.nav[isVertical ? "vertical" : "horizontal"].nav.color,
        size: "1rem",
        hover: Theme.nav[isVertical ? "vertical" : "horizontal"].nav.hover.color,
      }}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
