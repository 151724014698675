import React, { useLayoutEffect, useRef } from "react";
import { NavbarContainer } from "./NavbarStyles";

type NavBarProps = {
  top: number;
  fixedWidth?: number;
  setWidth: (value: number) => void;
  zIndex?: number;
};

const NavBar: React.FC<React.PropsWithChildren<NavBarProps>> = ({ top, fixedWidth, setWidth, zIndex, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!fixedWidth && ref.current && setWidth) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref, setWidth]);

  return (
    <NavbarContainer top={top} zIndex={zIndex} ref={ref}>
      {children}
    </NavbarContainer>
  );
};

export default NavBar;
