import React, { AnchorHTMLAttributes, useState } from "react";
import { NavigationLinkType, NavigationLinkWithSubLinks } from "../../../../../types";
import { useActivePath } from "../../shared/ActivePathContext";
import NavItem from "../../shared/navItem/NavItem";
import { NavMenuContainer, NavMenuDropdown } from "./NavMenuStyles";

type NavMenuProps = NavigationLinkWithSubLinks & {
  verticalNavWidth: number;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
};

const NavMenu = ({ verticalNavWidth, customLinkComponent, ...rest }: NavMenuProps) => {
  const { to, subLinks = [] } = rest;
  const { isActivePath } = useActivePath();
  const subTos: string[] = subLinks.filter((sl) => !!sl.to).map((sl) => sl.to!);
  if (!!to?.length) {
    subTos.push(to);
  }
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <NavMenuContainer onMouseOver={openMenu} onMouseLeave={closeMenu}>
      <NavItem {...rest} active={isActivePath(rest)} isVertical={true} customLinkComponent={customLinkComponent} />
      {menuOpen && (
        <NavMenuDropdown width={verticalNavWidth}>
          {subLinks
            .filter((subLink: NavigationLinkType) => subLink.visible !== false)
            .map((subLink: NavigationLinkType) => (
              <NavItem
                {...subLink}
                active={isActivePath(subLink)}
                key={subLink.name}
                customLinkComponent={customLinkComponent}
                isVertical={true}
              />
            ))}
        </NavMenuDropdown>
      )}
    </NavMenuContainer>
  );
};

export default NavMenu;
