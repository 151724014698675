import React from "react";
import { useTheme } from "../../../../../hooks";
import { AlertConstants, NavigationStatusType } from "../../../../../types";
import Alert from "../../../../alert/Alert";
import { StatusHolder } from "./MobileNavStatusStyles";

type MobileNavStatusProps = {
  displayEnvIndicator: boolean;
  status?: NavigationStatusType;
};

const MobileNavStatus: React.FC<MobileNavStatusProps> = ({ status, displayEnvIndicator }) => {
  const { Theme } = useTheme();
  return (
    <>
      {!!status && (
        <StatusHolder displayEnvIndicator={displayEnvIndicator}>
          <Alert
            id="navigation-status"
            type={status.type || AlertConstants.TYPES.DANGER}
            styles={{ ...Theme.alert, padding: "0", margin: "0", fontSize: ".75rem", borderRadius: 0 }}
          >
            <div className="text-center w-100">{status.text}</div>
          </Alert>
        </StatusHolder>
      )}
    </>
  );
};

export default MobileNavStatus;
