import React, { AnchorHTMLAttributes, useState } from "react";
import { useTheme } from "../../../../../hooks";
import { NavigationLinkType } from "../../../../../types";
import { checkForActive } from "../../../../../utils";
import { getWindow } from "../../../../../utils/WindowUtil";
import { Icon } from "../../../../icon";
import CustomLink from "../../shared/link/CustomLink";
import { NavItemContainer } from "./NavItemStyles";

type NavItemProps = NavigationLinkType & {
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
};

const NavItem: React.FC<NavItemProps> = ({ name, to, active, icon, iconCategory, onClick, customLinkComponent }) => {
  const location = getWindow()?.location;
  const { Theme } = useTheme();
  const [hovered, setHovered] = useState<boolean>(false);
  const itemActive: boolean = active || (!!to ? checkForActive(location, to) : false);

  const Content = () => (
    <NavItemContainer
      onClick={onClick}
      active={itemActive}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!!icon && (
        <Icon
          className="me-2"
          iconName={icon}
          iconCategory={iconCategory}
          styles={{
            color: hovered
              ? Theme.nav.mobile.hover.color
              : itemActive
              ? Theme.nav.mobile.active.color
              : Theme.nav.mobile.link.color,
            hoverColor: Theme.nav.mobile.hover.color,
          }}
        />
      )}
      {name}
    </NavItemContainer>
  );

  return !!to ? (
    <CustomLink to={to} customLinkComponent={customLinkComponent}>
      <Content />
    </CustomLink>
  ) : (
    <Content />
  );
};

export default NavItem;
