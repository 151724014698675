import { ColorType, ThemeType } from "../types";
import Cool, { CoolColors } from "./Cool";

export const NavyColors: ColorType = {
  ...CoolColors,
  "mlb-secondary-blue": "#2b4d7d",
};

const Navy: ThemeType = {
  ...Cool,
  name: "Navy",
  fontFamily: "Roboto, sans-serif",
  background: NavyColors.cloud,
  loading: {
    background: NavyColors["mlb-navy"],
    opacity: 1,
    color: NavyColors.white,
    fontSize: "1.1rem",
  },
  alert: {
    ...Cool.alert,
    danger: {
      background: NavyColors["mlb-red"],
      color: NavyColors["white"],
      border: NavyColors["mlb-red"],
    },
  },
  accordion: {
    ...Cool.accordion,
    action: {
      color: NavyColors["mlb-navy"],
      size: "1.5rem",
      padding: "0 1.25rem",
      margin: "0.375rem 0",
    },
    header: {
      ...Cool.accordion.header,
      color: NavyColors["mlb-navy"],
      background: NavyColors.white,
      border: `1px solid ${NavyColors["blue-grey"]}`,
    },
  },
  icon: {
    ...Cool.icon,
    color: NavyColors["mlb-navy"],
    hoverColor: NavyColors["blue"],
    disabledColor: NavyColors.fog,
  },
  iconAdorner: {
    color: NavyColors["mlb-navy"],
    hoverColor: NavyColors["mlb-blue"],
    size: "1.1rem",
  },
  label: {
    ...Cool.label,
    color: NavyColors["mlb-navy"],
    display: "flex",
    "asterisk-margin": "0rem 0rem 0rem 0.3rem",
  },
  checkbox: {
    ...Cool.checkbox,
    color: NavyColors["mlb-navy"],
  },
  select: {
    ...Cool.select,
    actionColor: NavyColors["mlb-navy"],
  },
  datepicker: {
    ...Cool.datepicker,
    input: {
      ...Cool.datepicker.input,
      actionColor: NavyColors["mlb-navy"],
      actionHoverColor: NavyColors["mlb-navy"],
    },
  },
  modal: {
    backdrop: NavyColors["mlb-navy"],
    backdropOpacity: 1,
    border: `1px solid ${NavyColors["mlb-navy"]}`,
    header: {
      ...Cool.modal.header,
      background: NavyColors["white"],
      fontColor: NavyColors["mlb-navy"],
      bottomBorderColor: NavyColors["mlb-navy"],
      closeColor: NavyColors["mlb-navy"],
    },
    body: {
      ...Cool.modal.body,
      background: NavyColors.fog,
      fontColor: NavyColors["mlb-navy"],
    },
    footer: {
      ...Cool.modal.footer,
      background: NavyColors["white"],
      fontColor: NavyColors["mlb-navy"],
      borderTopColor: NavyColors["mlb-navy"],
    },
  },
  nav: {
    ...Cool.nav,
    mobile: {
      ...Cool.nav.mobile,
      nav: {
        background: NavyColors["mlb-navy"],
        color: NavyColors.white,
      },
      menu: {
        background: NavyColors.fog,
        color: NavyColors["mlb-blue"],
        link: {
          background: NavyColors.white,
          color: NavyColors["mlb-secondary-blue"],
        },
      },
      link: {
        background: NavyColors.white,
        color: NavyColors["mlb-blue"],
        borderColor: NavyColors["mlb-navy"],
      },
      active: {
        background: NavyColors["mlb-secondary-blue"],
        color: NavyColors.white,
      },
      hover: {
        background: NavyColors["mlb-secondary-blue"],
        color: NavyColors.white,
      },
    },
  },
  navigation: {
    vertical: {
      ...Cool.navigation.vertical,
    },
    horizontal: {
      ...Cool.navigation.horizontal,
      active: {
        ...Cool.navigation.horizontal.active,
        background: NavyColors["mlb-secondary-blue"],
      },
      hover: {
        ...Cool.navigation.horizontal.hover,
        background: NavyColors["mlb-secondary-blue"],
        color: NavyColors.white,
      },
      mobile: {
        ...Cool.navigation.horizontal.mobile,
        background: NavyColors.white,
        linkColor: NavyColors.blue,
        linkBackground: NavyColors.white,
        sublinkColor: NavyColors.blue,
        sublinkBackground: NavyColors.white,
        dropdownColor: NavyColors["mlb-navy"],
        dropdownBackground: NavyColors.fog,
      },
      statuses: {
        success: {
          background: NavyColors.green,
          color: NavyColors.white,
          border: NavyColors.green,
        },
        info: {
          background: "#bdd7ff",
          color: "#3575d8",
          border: "#3575d8",
        },
        danger: {
          background: NavyColors["mlb-red"],
          color: NavyColors["white"],
          border: NavyColors["mlb-red"],
        },
        warning: {
          background: "#fcefb1",
          color: NavyColors.orange,
          border: NavyColors.orange,
        },
      },
      usernameDropdown: {
        color: NavyColors["white"],
        background: NavyColors["mlb-navy"],
        hover: {
          color: NavyColors["mlb-navy"],
          background: NavyColors["white"],
        },
      },
    },
  },
  hint: {
    ...Cool.hint,
    color: NavyColors["blue-grey"],
    fontStyle: "normal",
  },
  popover: {
    backdrop: NavyColors["mlb-navy"],
    backdropOpacity: 1,
    border: `1px solid ${NavyColors["mlb-navy"]}`,
    header: {
      ...Cool.popover.header,
      background: NavyColors.white,
    },
    body: {
      ...Cool.popover.body,
      fontColor: NavyColors["mlb-navy"],
      background: NavyColors.fog,
    },
    footer: {
      ...Cool.popover.footer,
      background: NavyColors.white,
    },
  },
  stickyFooter: {
    ...Cool.stickyFooter,
    color: NavyColors["mlb-navy"],
  },
  button: {
    ...Cool.button,
    primary: {
      "background-color": NavyColors["blue"],
      "border-color": `${NavyColors["mlb-navy"]}; border-width: 2px;`,
      color: NavyColors.white,
      hover: NavyColors["mlb-blue"],
      opacity: 1,
    },
    secondary: {
      "background-color": NavyColors.white,
      "border-color": `${NavyColors["mlb-navy"]}; border-width: 2px;`,
      color: NavyColors["mlb-navy"],
      hover: NavyColors["light-grey"],
      opacity: 1,
    },
  },
  link: {
    ...Cool.link,
    color: NavyColors["mlb-navy"],
  },
  stepper: {
    ...Cool.stepper,
    spacerColor: NavyColors["mlb-navy"],
    disabled: {
      ...Cool.stepper.disabled,
      borderColor: NavyColors["mlb-navy"],
    },
  },
  subSection: {
    ...Cool.subSection,
    background: "inherit",
    borderBottom: `1px solid ${NavyColors["mlb-navy"]}`,
    border: "",
    color: NavyColors["mlb-navy"],
    padding: "0",
  },
  typography: {
    span: {
      size: ".9rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    div: {
      size: ".9rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body1: {
      size: ".9rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body2: {
      size: "1rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body3: {
      size: "1.1rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    caption: {
      size: ".9rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "italic",
      width: "100%",
    },
    h1: {
      size: "6rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h2: {
      size: "3.75rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h3: {
      size: "3rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h4: {
      size: "2.125rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h5: {
      size: "1.5rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h6: {
      size: "1.25rem",
      color: NavyColors["mlb-navy"],
      weight: 600,
      style: "normal",
      width: "100%",
    },
    subtitle1: {
      size: ".8rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "normal",
      width: "100%",
    },
    subtitle2: {
      size: ".8rem",
      color: NavyColors["mlb-navy"],
      weight: 400,
      style: "italic",
      width: "100%",
    },
  },
  datatable: {
    ...Cool.datatable,
    pagination: {
      color: NavyColors.blue,
      background: NavyColors.white,
      active: {
        color: NavyColors.white,
        background: NavyColors.blue,
      },
      disabled: {
        color: NavyColors.fog,
        background: NavyColors.white,
      },
    },
    row: {
      ...Cool.datatable.row,
      separatorColor: NavyColors.fog,
    },
    header: {
      ...Cool.datatable.header,
      fontWeight: 500,
    },
    moreActions: {
      ...Cool.datatable.moreActions,
      icon: {
        color: NavyColors["mlb-navy"],
        hoverColor: NavyColors["blue"],
        disabledColor: NavyColors.fog,
      },
      popoverAction: {
        color: NavyColors["mlb-navy"],
        fontSize: "14px",
        hoverBackgroundColor: NavyColors.white,
        padding: "8px",
        borderColor: NavyColors["mlb-navy"],
      },
    },
  },
  danger: {
    fontWeight: 300,
    fontSize: ".75rem",
    color: CoolColors["mlb-red"],
    fontStyle: "normal",
  },
  hr: {
    color: NavyColors.fog,
  },
  toggleButtons: {
    background: NavyColors.white,
    buttonBackgroundColor: NavyColors.white,
    border: `1px solid ${NavyColors.fog}`,
    buttonActiveColor: NavyColors["mlb-navy"],
  },
  progress: {
    primary: {
      color: CoolColors["mlb-blue"],
    },
    secondary: {
      color: CoolColors.grey,
    },
    success: {
      color: CoolColors.green,
    },
    error: {
      color: CoolColors["mlb-red"],
    },
  },
  statusDrawer: {
    ...Cool.statusDrawer,
    header: {
      ...Cool.statusDrawer.header,
      background: NavyColors["mlb-navy"],
    },
  },
  breakpoints: {
    ...Cool.breakpoints,
  },
};

export default Navy;
