import styled from "styled-components";

export type MobileNavStyleProps = {
  displayEnvIndicator: boolean;
  hasStatus: boolean;
};

const calculateHeight = (displayEnvIndicator: boolean, hasStatus: boolean): number => {
  let height = 45;
  if (displayEnvIndicator) {
    height += 18;
  }
  if (hasStatus) {
    height += 18;
  }
  return height;
};

export const MobileNavigationSpacer = styled.div<MobileNavStyleProps>`
  width: 100%;
  min-height: ${(props) => calculateHeight(props.displayEnvIndicator, props.hasStatus)}px;
  z-index: 1049;
`;

export const MobileNavigationContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1051;
`;

export const EndComponentHolder = styled.div`
  width: 45px;
  text-align: center;
`;
