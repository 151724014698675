import React from "react";
import { NavbarContainer } from "./NavbarStyles";

type NavbarProps = {
  displayEnvIndicator: boolean;
};

const Navbar: React.FC<React.PropsWithChildren<NavbarProps>> = ({ children, displayEnvIndicator = false }) => {
  return <NavbarContainer displayEnvIndicator={displayEnvIndicator}>{children}</NavbarContainer>;
};

export default Navbar;
