import styled from "styled-components";

export const NavMenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
`;

type NavMenuStyleProps = {
  width: number;
};

export const NavMenuDropdown = styled.ul<NavMenuStyleProps>`
  left: ${({ width }) => width}px;
  position: absolute;
  width: 200px;
  color: ${(props) => props.theme.nav.vertical.nav.color};
  background: ${(props) => props.theme.nav.vertical.nav.background};
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0.25rem 0.5rem;
  z-index: 1055;
  border: ${(props) => props.theme.nav.vertical?.nav?.border ?? "none"};
`;
