import React from "react";
import { Hamburger } from "./HamburgerStyles";

type NavHamburgerProps = {
  open: boolean;
  toggle: (value: boolean) => void;
};

const NavHamburger: React.FC<React.PropsWithChildren<NavHamburgerProps>> = ({ open, toggle }) => {
  const mobileNavClass = open ? "mobile-nav-open" : "mobile-nav-closed";
  return (
    <Hamburger
      className={`navbar-toggler navbar-toggler-right custom-toggler pull-left mx-2 ${mobileNavClass}`}
      onClick={() => toggle(!open)}
    >
      <span className="icon-bar top-bar" />
      <span className="icon-bar middle-bar" />
      <span className="icon-bar bottom-bar" />
      <span className="sr-only">Toggle navigation</span>
    </Hamburger>
  );
};

export default NavHamburger;
