import React, { AnchorHTMLAttributes, useRef } from "react";
import { BuildType } from "../../../../../types";
import Popover from "../../../../popover/popover/Popover";
import CustomLink from "../link/CustomLink";
import NavImage from "../navImage/NavImage";
import { BrandContainer } from "./BrandStyles";

type BrandProps = {
  logo?: string | undefined;
  text?: string | undefined;
  link?: string | undefined;
  contentHeight?: string | number | undefined;
  builds?: BuildType[];
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
  isVertical?: boolean;
};

const Brand: React.FC<BrandProps> = ({ logo, text, link, contentHeight, builds, customLinkComponent, isVertical }) => {
  const buildRef = useRef(null);

  const Content = () => (
    <>
      {!!logo && <NavImage src={logo} height={contentHeight} />}
      {!!text && <>{text}</>}
    </>
  );

  const hasBuilds = !!builds?.length;

  let brandHeight: number | undefined;
  if (typeof contentHeight === "string") {
    brandHeight = parseInt(contentHeight.replace(/[^0-9]/g, ""));
  } else {
    brandHeight = contentHeight;
  }

  return (
    <>
      {hasBuilds && (
        <Popover ref={buildRef} trigger="hover" delay={2000} location="right">
          <Popover.Header>Build Version</Popover.Header>
          <Popover.Body>
            {builds?.map((build: BuildType) => (
              <div key={build.build}>
                {build.build} {build.name}
              </div>
            ))}
          </Popover.Body>
        </Popover>
      )}
      <BrandContainer height={brandHeight} ref={hasBuilds ? buildRef : undefined} isVertical={isVertical}>
        {!!link?.length ? (
          <CustomLink to={link} customLinkComponent={customLinkComponent} isVertical={isVertical}>
            <Content />
          </CustomLink>
        ) : (
          <Content />
        )}
      </BrandContainer>
    </>
  );
};

export default Brand;
