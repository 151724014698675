import styled from "styled-components";

type QuickLinkStyleProps = {
  active: boolean;
};

export const QuickLinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const QuickLinkContainer = styled.div<QuickLinkStyleProps>`
  padding: 0.5rem;
  border-radius: 4px;
  ${(props) =>
    props.active
      ? `background: ${props.theme.nav.mobile.active.background}; color: ${props.theme.nav.mobile.active.color}`
      : ""}
`;
