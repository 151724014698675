import { NavigationLinkWithSubLinks } from "types";

export const checkForActive = (location: Location | undefined, ...to: string[]): boolean => {
  if (!!location?.pathname) {
    return checkForActivePath(location.pathname.toLowerCase(), ...to);
  } else {
    return false;
  }
};

export const checkForActivePath = (pathName: string, ...to: string[]): boolean => {
  const anyMatch: boolean[] = to.map((t: string) => matchPath(pathName, t));
  return !!to && anyMatch.includes(true);
};

export function matchPath(navLink: string, locationPath: string, navLinks?: NavigationLinkWithSubLinks[]): boolean {
  if (!navLink.length || !locationPath.length) {
    return navLink.length === locationPath.length;
  }
  if (navLink === locationPath) {
    return true;
  }
  //logic for if you are a child route of a parent navLink
  if (locationPath.includes(navLink) && !navLinks?.map((nl) => nl.to).includes(locationPath)) {
    return true;
  }
  const pathSegments = locationPath.split("/");
  const navSegments = navLink.split("/");
  for (let i = 0; i < pathSegments.length; i++) {
    if (pathSegments[i][0] === ":") {
      if (!navSegments.length) {
        return false;
      }
    } else {
      if (pathSegments[i] !== navSegments[i]) {
        return false;
      }
    }
  }
  return pathSegments.length === navSegments.length;
}
