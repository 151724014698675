import React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import Traditional from "./themes/Traditional";
import { ThemeType } from "./types";

interface BCRProps {
  theme?: ThemeType;
  children?: React.ReactNode;
  hideContainer?: boolean;
  containerClassname?: string;
  containerStyle?: React.CSSProperties;
}

interface BCRStyleProps {
  styles: ThemeType;
}

const GlobalStyles = createGlobalStyle<BCRStyleProps>`
  html, body {
    font-family: ${(props) => props.styles.fontFamily};
    background: ${(props) => props.styles.background};
    width: 100%;
    height: 100%;
  }

  .bcr-popover-closed {
      display: none;
  }

  .bcr-popover {
      z-index: 1100;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BCR = ({
  children,
  containerClassname,
  containerStyle,
  theme = Traditional,
  hideContainer = false,
}: BCRProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={theme} />
      {hideContainer ? (
        <>{children}</>
      ) : (
        <Container className={containerClassname} style={containerStyle}>
          {children}
        </Container>
      )}
    </ThemeProvider>
  );
};

export default BCR;
