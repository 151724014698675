import React, { createContext, useCallback, useContext, useState } from "react";
import { LoadingType } from "../../types/LoadingTypes";

interface LoadingContextState {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setLoadingNonBlocking: (value: boolean) => void;
  type: LoadingType;
  setType: (type: LoadingType) => void;
  animated: boolean;
  setAnimated: (value: boolean) => void;
  label: string;
  setLabel: (label: string) => void;
  blocking: boolean;
  setBlocking: (value: boolean) => void;
}

const LoadingContext = createContext<LoadingContextState | undefined>(undefined);

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  // hooks
  const [label, setLabel] = useState("");
  const [loading, setLoading] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [blocking, setBlocking] = useState(true);
  const [type, setType] = useState<LoadingType>("player");

  // functions
  const setLoadingLocal = useCallback((loading: boolean) => {
    setLoading(loading);
    setBlocking(true);
  }, []);

  const setLoadingNonBlocking = useCallback((loading: boolean) => {
    setBlocking(false);
    setLoading(loading);
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading: setLoadingLocal,
        setLoadingNonBlocking,
        type,
        setType,
        animated,
        setAnimated,
        label,
        setLabel,
        blocking,
        setBlocking,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const loadingContext = useContext(LoadingContext);
  if (loadingContext === undefined) {
    throw new Error(`useLoading must be used within a LoadingProvider`);
  }
  return loadingContext;
};

export { LoadingContext, LoadingProvider, useLoading };
