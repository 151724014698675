import React, { AnchorHTMLAttributes } from "react";
import { useTheme } from "../../../../../hooks";
import { NavigationLinkType } from "../../../../../types";
import { checkForActive } from "../../../../../utils";
import { getWindow } from "../../../../../utils/WindowUtil";
import { Icon } from "../../../../icon";
import CustomLink from "../../shared/link/CustomLink";
import { QuickLinkContainer } from "./QuickLinkStyles";

type QuickLinkProps = NavigationLinkType & {
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
};

const QuickLink = ({ active, iconCategory, customLinkComponent, to = "", icon = "" }: QuickLinkProps) => {
  const { Theme } = useTheme();
  const location = getWindow()?.location;
  const itemActive: boolean = active || (!!to ? checkForActive(location, to) : false);
  return (
    <CustomLink to={to} customLinkComponent={customLinkComponent}>
      <QuickLinkContainer active={itemActive}>
        <Icon
          iconName={icon}
          iconCategory={iconCategory}
          styles={{
            color: Theme.nav.mobile.nav.color,
            hoverColor: Theme.nav.mobile.nav.color,
          }}
        />
      </QuickLinkContainer>
    </CustomLink>
  );
};

export default QuickLink;
