import React, { AnchorHTMLAttributes, useState } from "react";
import { EnvironmentType, NavigationConfigType } from "../../../../types";
import { useActivePath } from "../shared/ActivePathContext";
import Brand from "../shared/brand/Brand";
import Nav from "../shared/nav/Nav";
import NavItem from "../shared/navItem/NavItem";
import NavSignoutBar from "../shared/navSignoutBar/NavSignoutBar";
import { BrandHolder, VerticalNavContainer } from "./VerticalNavStyles";
import ComponentBar from "./componentBar/ComponentBar";
import NavMenu from "./navMenu/NavMenu";
import NavBar from "./navbar/Navbar";
interface VerticalNavigationProps {
  config: NavigationConfigType;
  children?: React.ReactNode;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const signoutHeight = 20;

const VerticalNavigation = ({ config, children, customLinkComponent }: VerticalNavigationProps) => {
  const {
    loggedIn,
    username,
    startLogo,
    endLogo,
    verticalWidth,
    navigationLinks = [],
    usernameDropdownLinks = [],
    builds = [],
    env = "prod" as EnvironmentType,
    displayEnvIndicator = false,
    displayPermissionsIndicator = false,
    permissions = undefined,
    status,
    zIndex,
  } = config;

  const [verticalNavWidth, setVerticalNavWidth] = useState<number>(verticalWidth ?? 40);
  const [crossHeight, setCrossHeight] = useState<number>(40);
  const { isActivePath } = useActivePath();

  return (
    <>
      {loggedIn && (
        <NavSignoutBar
          username={username}
          links={usernameDropdownLinks}
          displayEnvIndicator={displayEnvIndicator}
          displayPermissionsIndicator={displayPermissionsIndicator}
          permissions={permissions}
          env={env}
          isVertical={true}
        />
      )}

      <VerticalNavContainer>
        <ComponentBar setHeight={setCrossHeight} top={signoutHeight}>
          <div className="d-flex">
            <BrandHolder>
              {!!startLogo?.visible && (
                <Brand
                  logo={startLogo!.logo}
                  link={startLogo!.link}
                  text={startLogo!.text}
                  contentHeight={startLogo!.logoHeight}
                  builds={builds}
                  customLinkComponent={customLinkComponent}
                  isVertical={true}
                />
              )}
            </BrandHolder>
            {children}
          </div>
        </ComponentBar>
        <NavBar
          top={crossHeight + signoutHeight}
          fixedWidth={verticalWidth}
          setWidth={setVerticalNavWidth}
          zIndex={zIndex}
        >
          {loggedIn && !!navigationLinks?.length && (
            <Nav direction="column" width={verticalWidth ? verticalWidth + "px" : undefined}>
              {navigationLinks.map((link) => {
                if (link.visible !== false) {
                  if (!!link.subLinks?.length) {
                    return (
                      <NavMenu
                        {...link}
                        key={link.name}
                        verticalNavWidth={verticalNavWidth}
                        customLinkComponent={customLinkComponent}
                      />
                    );
                  } else {
                    return (
                      <NavItem
                        {...link}
                        active={isActivePath(link)}
                        isVertical={true}
                        key={link.name}
                        customLinkComponent={customLinkComponent}
                      />
                    );
                  }
                } else {
                  return null;
                }
              })}
            </Nav>
          )}

          {!!endLogo?.visible && (
            <Nav align="center" className="mt-auto mb-2">
              <Brand
                logo={endLogo.logo}
                link={endLogo.link}
                text={endLogo.text}
                contentHeight={endLogo!.logoHeight}
                customLinkComponent={customLinkComponent}
                isVertical={true}
              />
            </Nav>
          )}
        </NavBar>
      </VerticalNavContainer>
    </>
  );
};

export default VerticalNavigation;
