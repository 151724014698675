import React, { useLayoutEffect, useRef } from "react";
import { ComponentBarContainer } from "./ComponentBarStyles";

type ComponentBarProps = {
  top: number;
  height?: string | number;
  setHeight: (value: number) => void;
};

const ComponentBar: React.FC<React.PropsWithChildren<ComponentBarProps>> = ({ height, setHeight, top, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (height) {
      if (typeof height === "string") {
        setHeight(parseInt(height.replace(/[^0-9]/g, "")));
      } else {
        setHeight(height);
      }
    } else if (ref.current && setHeight) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref, height, setHeight]);

  return (
    <ComponentBarContainer top={top} ref={ref}>
      {children}
    </ComponentBarContainer>
  );
};

export default ComponentBar;
