import React, { useState } from "react";
import { useTheme } from "../../../../../hooks";
import { NavigationLinkType } from "../../../../../types";
import { Icon } from "../../../../icon";
import { NavItemContentContainer, NavItemName } from "./NavItemStyles";

const NavItemContent: React.FC<NavigationLinkType> = ({
  id,
  onClick,
  icon,
  iconCategory,
  displayName = true,
  name,
  active,
  className = "",
  isVertical = false,
}) => {
  const { Theme } = useTheme();
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <NavItemContentContainer
      id={id}
      className={className}
      onClick={!!onClick ? onClick : undefined}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!!icon && (
        <Icon
          iconName={icon}
          iconCategory={iconCategory}
          styles={{
            color: hovered
              ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.hover.color
              : active
              ? Theme.nav[isVertical ? "vertical" : "horizontal"].nav.active.color
              : Theme.nav[isVertical ? "vertical" : "horizontal"].nav.color,
            hoverColor: Theme.nav[isVertical ? "vertical" : "horizontal"].nav.hover.color,
          }}
        />
      )}
      {displayName && <NavItemName>{name}</NavItemName>}
    </NavItemContentContainer>
  );
};

export default NavItemContent;
