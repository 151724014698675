import styled from "styled-components";

export const StatusHolder = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1050;
`;
