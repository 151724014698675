import React, { AnchorHTMLAttributes, useEffect, useState } from "react";
import { useTheme } from "../../../../../hooks";
import { NavigationLinkType, NavigationLinkWithSubLinks } from "../../../../../types";
import { checkForActive } from "../../../../../utils";
import { getWindow } from "../../../../../utils/WindowUtil";
import { Icon } from "../../../../icon";
import CustomLink from "../../shared/link/CustomLink";
import { NavMenuCollapsable, NavMenuContainer, NavMenuLink } from "./NavMenuStyles";

type SubLinkContentProps = {
  subLink: NavigationLinkType;
  subLinkActive: boolean;
  onClick?: () => void;
};

const SubLinkContent: React.FC<SubLinkContentProps> = ({ subLink, subLinkActive, onClick }) => {
  const { Theme } = useTheme();
  const [hovered, setHovered] = useState<boolean>(false);
  return (
    <NavMenuLink
      active={subLinkActive}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        if (!!onClick) {
          onClick();
        }
        if (!!subLink.onClick) {
          subLink.onClick();
        }
      }}
    >
      {!!subLink.icon && (
        <Icon
          className="me-2"
          iconName={subLink.icon}
          iconCategory={subLink.iconCategory}
          styles={{
            color: hovered
              ? Theme.nav.mobile.hover.color
              : subLinkActive
              ? Theme.nav.mobile.active.color
              : Theme.nav.mobile.menu.link.color,
            hoverColor: Theme.nav.mobile.hover.color,
          }}
        />
      )}
      {subLink.name}
    </NavMenuLink>
  );
};

type NavMenuProps = NavigationLinkWithSubLinks & {
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
};

const NavMenu: React.FC<NavMenuProps> = ({ customLinkComponent, ...rest }) => {
  const { to, subLinks = [], name, icon, iconCategory, onClick } = rest;
  const { Theme } = useTheme();
  const location = getWindow()?.location;
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [active, setActive] = useState<boolean>(true);
  const [hovered, setHovered] = useState<boolean>(false);
  const [visibleSubLinks, setVisibleSubLinks] = useState<NavigationLinkType[]>([]);

  useEffect(() => {
    const subTos: string[] = visibleSubLinks.filter((sl) => !!sl.to).map((sl) => sl.to!);
    if (!!to?.length) {
      subTos.push(to);
    }
    setActive(checkForActive(location, ...subTos));
  }, [visibleSubLinks]);

  useEffect(() => {
    const visible = subLinks.filter((subLink: NavigationLinkType) => subLink.visible !== false);
    setVisibleSubLinks(visible);
  }, [subLinks]);

  return (
    <NavMenuContainer>
      <NavMenuCollapsable
        active={active}
        onClick={() => setMenuOpen(!menuOpen)}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {!!icon && (
          <Icon
            className="me-2"
            iconName={icon}
            iconCategory={iconCategory}
            styles={{
              color: hovered
                ? Theme.nav.mobile.hover.color
                : active
                ? Theme.nav.mobile.active.color
                : Theme.nav.mobile.menu.color,
              hoverColor: Theme.nav.mobile.hover.color,
            }}
          />
        )}
        {name}
        <div className="ms-auto">
          <Icon
            className=""
            iconName={menuOpen ? "fa-angle-up" : "fa-angle-down"}
            styles={{
              color: hovered
                ? Theme.nav.mobile.hover.color
                : active
                ? Theme.nav.mobile.active.color
                : Theme.nav.mobile.menu.color,
              hoverColor: Theme.nav.mobile.hover.color,
            }}
          />
        </div>
      </NavMenuCollapsable>
      {menuOpen &&
        visibleSubLinks.map((subLink: NavigationLinkType) => {
          const subLinkActive: boolean = !!subLink.to ? checkForActive(location, subLink.to!) : false;
          return !!subLink.to ? (
            <CustomLink to={subLink.to} customLinkComponent={customLinkComponent} key={subLink.name}>
              <SubLinkContent subLink={subLink} onClick={onClick} subLinkActive={subLinkActive} />
            </CustomLink>
          ) : (
            <SubLinkContent key={subLink.name} subLink={subLink} onClick={onClick} subLinkActive={subLinkActive} />
          );
        })}
    </NavMenuContainer>
  );
};

export default NavMenu;
