import React, { useLayoutEffect, useRef } from "react";
import { Align, Direction } from "../../../../../types";
import { NavContainer } from "./NavStyles";

type NavProps = {
  align?: Align;
  direction?: Direction;
  className?: string;
  setHeight?: (value: number) => void;
  width?: string;
};

const Nav: React.FC<React.PropsWithChildren<NavProps>> = ({
  align = "start",
  direction = "row",
  className,
  setHeight,
  width,
  children,
}) => {
  const ref = useRef<HTMLUListElement>(null);

  useLayoutEffect(() => {
    if (ref.current && setHeight) {
      setHeight(ref.current.offsetHeight);
    }
  }, [ref, setHeight]);

  return (
    <NavContainer align={align} direction={direction} className={className} ref={ref} navWidth={width}>
      {children}
    </NavContainer>
  );
};

export default Nav;
