import styled from "styled-components";

type NavItemStyleProps = {
  active: boolean;
};

export const NavItemContainer = styled.li<NavItemStyleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.nav.fontFamily};
  color: ${(props) => (props.active ? props.theme.nav.mobile.active.color : props.theme.nav.mobile.link.color)};
  background: ${(props) =>
    props.active ? props.theme.nav.mobile.active.background : props.theme.nav.mobile.link.background};
  border-bottom: 1px solid ${(props) => props.theme.nav.mobile.link.borderColor};

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.nav.mobile.hover.background};
    color: ${(props) => props.theme.nav.mobile.hover.color};
  }
`;
