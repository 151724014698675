import styled from "styled-components";

type NavMenuStylesProps = {
  active: boolean;
};

export const NavMenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const NavMenuCollapsable = styled.li<NavMenuStylesProps>`
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 2.5rem;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.nav.fontFamily};
  color: ${(props) => (props.active ? props.theme.nav.mobile.active.color : props.theme.nav.mobile.menu.color)};
  background: ${(props) =>
    props.active ? props.theme.nav.mobile.active.background : props.theme.nav.mobile.menu.background};
  border-bottom: 1px solid ${(props) => props.theme.nav.mobile.link.borderColor};

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.nav.mobile.hover.background};
    color: ${(props) => props.theme.nav.mobile.hover.color};
  }
`;

export const NavMenuLink = styled.li<NavMenuStylesProps>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 4.5rem;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.nav.fontFamily};
  color: ${(props) => (props.active ? props.theme.nav.mobile.active.color : props.theme.nav.mobile.menu.link.color)};
  background: ${(props) =>
    props.active ? props.theme.nav.mobile.active.background : props.theme.nav.mobile.menu.link.background};
  border-bottom: 1px solid ${(props) => props.theme.nav.mobile.link.borderColor};

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.nav.mobile.hover.background};
    color: ${(props) => props.theme.nav.mobile.hover.color};
  }
`;
