import React, { AnchorHTMLAttributes, useState } from "react";
import { NavigationLinkType } from "../../../../../types";
import { useActivePath } from "../ActivePathContext";
import CustomLink from "../link/CustomLink";
import NavItemContent from "./NavItemContent";
import { NavItemContainer } from "./NavItemStyles";

type NavItemProps = NavigationLinkType & {
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
  isVertical?: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  name,
  to,
  id,
  iconCategory,
  icon,
  onClick,
  visible = true,
  active = false,
  displayName = true,
  customLinkComponent,
  isVertical = false,
}) => {
  const { makePathActive } = useActivePath();
  const [hovered, setHovered] = useState<boolean>(false);

  return !!visible ? (
    <NavItemContainer
      active={active}
      onClick={() => !!makePathActive && makePathActive(to || name)}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      isVertical={isVertical}
    >
      {to ? (
        <CustomLink
          to={to}
          active={active}
          hovered={hovered}
          customLinkComponent={customLinkComponent}
          isVertical={isVertical}
        >
          <NavItemContent
            id={id}
            name={name}
            icon={icon}
            iconCategory={iconCategory}
            onClick={onClick}
            displayName={displayName}
            isVertical={isVertical}
          />
        </CustomLink>
      ) : (
        <NavItemContent
          id={id}
          name={name}
          icon={icon}
          iconCategory={iconCategory}
          onClick={onClick}
          displayName={displayName}
          isVertical={isVertical}
        />
      )}
    </NavItemContainer>
  ) : null;
};

export default NavItem;
