import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../../../hooks";
import { EnvironmentType, NavigationLinkType } from "../../../../../types";
import { Icon } from "../../../../icon";
import { EnvIndicator } from "../env";
import NavItemContent from "../navItem/NavItemContent";
import {
  NavSignoutBarContainer,
  Permission,
  Username,
  UsernameContainer,
  UsernameDropdownItem,
  UsernameMenuDropdown,
} from "./NavSignoutBarStyles";

const UsernameDropdownItemContent = styled(NavItemContent)`
  padding: 0.5rem 0.25rem;
`;

type NavSignoutBarProps = {
  username?: string;
  links?: NavigationLinkType[];
  displayEnvIndicator?: boolean;
  displayPermissionsIndicator?: boolean;
  permissions?: string;
  env: EnvironmentType;
  isVertical?: boolean;
};

const NavSignoutBar: React.FC<NavSignoutBarProps> = ({
  username = "",
  links = [],
  displayEnvIndicator = false,
  displayPermissionsIndicator = false,
  permissions,
  env,
  isVertical = false,
}) => {
  const { Theme } = useTheme();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <NavSignoutBarContainer isVertical={isVertical}>
      {displayEnvIndicator && <EnvIndicator env={env} />}
      {displayPermissionsIndicator && !!permissions?.length && (
        <Permission isVertical={isVertical}>{permissions}</Permission>
      )}
      {!!username && (
        <UsernameContainer onMouseOver={openMenu} onMouseLeave={closeMenu}>
          <Username isVertical={isVertical}>{username}</Username>
          {!!links?.length && (
            <Icon
              iconName="fa-angle-down"
              styles={{
                color: Theme.nav[isVertical ? "vertical" : "horizontal"].username.color,
                hoverColor: Theme.nav[isVertical ? "vertical" : "horizontal"].username.color,
              }}
            />
          )}
          {menuOpen && (
            <UsernameMenuDropdown isVertical={isVertical}>
              {links?.map((link: NavigationLinkType) => (
                <UsernameDropdownItem key={link.name} isVertical={isVertical}>
                  <UsernameDropdownItemContent {...link} />
                </UsernameDropdownItem>
              ))}
            </UsernameMenuDropdown>
          )}
        </UsernameContainer>
      )}
    </NavSignoutBarContainer>
  );
};

export default NavSignoutBar;
