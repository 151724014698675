import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { AlertConstants, AlertThemeType, AlertTypes } from "../../types";
import { Icon } from "../icon";
import { AlertStyles, StyledAlert } from "./AlertStyles";

type AlertProps = {
  type: AlertTypes;
  id: string | number | undefined;
  className?: string;
  onRemove?: (id: number | string) => void;
  styles?: AlertThemeType;
};

const Alert: React.FC<React.PropsWithChildren<AlertProps>> = ({
  type,
  className = "",
  children,
  id,
  onRemove,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: AlertThemeType = { ...Theme.alert, ...styles };

  const DangerTheme: AlertStyles = StylesOverride["danger"];
  const SuccessTheme: AlertStyles = StylesOverride["success"];
  const WarningTheme: AlertStyles = StylesOverride["warning"];
  const InfoTheme: AlertStyles = StylesOverride["info"];

  const getTheme = (theme: string): AlertStyles => {
    const themeStr = !!theme ? theme.toLowerCase() : "";
    switch (themeStr) {
      case AlertConstants.TYPES.DANGER:
        return DangerTheme;
      case AlertConstants.TYPES.WARNING:
        return WarningTheme;
      case AlertConstants.TYPES.INFO:
        return InfoTheme;
      case AlertConstants.TYPES.SUCCESS:
      default:
        return SuccessTheme;
    }
  };

  const removeAlert = () => {
    if (onRemove && !!id) {
      onRemove!(id);
    }
  };

  const theme: AlertStyles = getTheme(type);
  return (
    <StyledAlert type={theme} styles={StylesOverride} className={clsx("best-alert", className)}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-wrap w-100 h-100">{children}</div>
        {!!onRemove && (
          <Icon className="ps-2" iconName="fa-times-circle" styles={{ color: theme.color }} onClick={removeAlert} />
        )}
      </div>
    </StyledAlert>
  );
};

export default Alert;
