import React from "react";
import { NavbarContainer } from "./NavbarStyles";

type NavbarProps = {
  loggedIn: boolean;
};

const Navbar: React.FC<React.PropsWithChildren<NavbarProps>> = ({ loggedIn, children }) => {
  return <NavbarContainer loggedIn={loggedIn}>{children}</NavbarContainer>;
};

export default Navbar;
