import styled from "styled-components";
import { AlertThemeType } from "../../types";

export type AlertStyles = {
  background?: string;
  border?: string;
  color?: string;
};

type AlertThemeStyles = {
  styles: AlertThemeType;
  type: AlertStyles;
};

export const MessageWrapper = styled.div`
  pointer-events: all;
  position: fixed;
  text-align: center;
  width: 50%;
  left: 25%;
  top: 75px;
  z-index: 1150;
`;

export const Message = styled.div`
  display: inline-block;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  width: 100%;
`;

export const StyledAlert = styled.div<AlertThemeStyles>`
  &&& {
    background-color: ${(props) => props.type["background"]};
    color: ${(props) => props.type["color"]};
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    padding: ${(props) => props.styles.padding};
    margin: ${(props) => props.styles.margin};
    border: 1px solid ${(props) => props.type["border"]};
    border-radius: ${(props) => props.styles.borderRadius};
    font-size: ${(props) => props.styles.fontSize};
  }
`;
