import React, { AnchorHTMLAttributes } from "react";
import { useBreakpoints } from "../../../hooks";
import { NavigationConfigType } from "../../../types";
import HorizontalNavigation from "./horizontal/HorizontalNavigation";
import { MobileNavigation } from "./mobile";
import { ActivePathContextProvider } from "./shared/ActivePathContext";
import { VerticalNavigation } from "./vertical";

interface NavigationProps {
  children?: React.ReactNode;
  config: NavigationConfigType;
  vertical?: boolean;
  customLinkComponent?: React.FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>;
}

const Navigation = ({ config, customLinkComponent, children, vertical = false }: NavigationProps) => {
  // hooks
  const { isMobile } = useBreakpoints();

  // constants
  const props = { config, customLinkComponent };

  // render
  if (isMobile) {
    return <MobileNavigation {...props} />;
  }

  if (vertical) {
    return (
      <ActivePathContextProvider navLinks={config.navigationLinks}>
        <VerticalNavigation {...props}>{children}</VerticalNavigation>
      </ActivePathContextProvider>
    );
  }

  return (
    <ActivePathContextProvider navLinks={config.navigationLinks}>
      <HorizontalNavigation {...props} />
    </ActivePathContextProvider>
  );
};

export default Navigation;
